import { AuthClient, AuthorizeResult, withAuthentication } from  '@sede-x/auth'
import  styled  from  'styled-components'
import  * as  React  from  'react'
import  FirstComponent  from  './FirstComponent';

type Props = {
    authClient: AuthClient;
    loggedIn: boolean;
    authorizeResult: AuthorizeResult;
}

type PromiseType = () =>  Promise<void>

export const LogoutContext = React.createContext<PromiseType|undefined>(undefined)

class Auth extends React.Component<Props> {

    authorize:PromiseType = async () => {
        try {
            await this.props.authClient.logIn();
        } catch (e) {
            // handle error
        }
    }

    logout:PromiseType = async () => {
        try {
            await this.props.authClient.logOut();
        } catch (e) {
            // handle error
        }
    }

    refresh:PromiseType = async () => {
        try {
            await this.props.authClient.refreshAuthToken();
        } catch (e) {
            // handle error
        }
    }
    
    render() {
        return (
            <div>
                {!this.props.loggedIn ? (
                    <div>
                        <Container>
                            <Wrapper>
                                <Title>Please log in</Title>
                                <Button onClick={this.authorize}> Login </Button>
                            </Wrapper>
                        </Container>
                    </div>
                ) : (
                    <div>
                        <LogoutContext.Provider value={this.logout}>
                            <FirstComponent />
                        </LogoutContext.Provider>
                    </div>
                )}
            </div>
        );
    }
}

const  Title = styled.h2`
    ...
`
const  Button = styled.button`
    ...
`
const  Container = styled.div`
    ...
`
const Wrapper = styled.div`
    ...
`
export default withAuthentication(Auth);