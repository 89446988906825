import * as React from "react";
import * as ReactDOM from "react-dom";
import App from "./components/App";
import FirstComponent from './components/FirstComponent'
ReactDOM.render(
    <div>
      <h1>Hello, Welcome to Shell React App with Authentication. </h1>
      <App/>
        
      
    </div>,
    document.getElementById("root")
);