import { AuthenticationProvider } from '@sede-x/auth';
import * as React from 'react';
import Auth from './auth'

interface  authConfigType {
	issuer: string;
	clientId: string;
	redirectUrl: string;
}

const  authConfig:authConfigType = {
	issuer:process.env.REACT_APP_ISSUER!,
	clientId:process.env.REACT_APP_CLIENTID!,
	redirectUrl:process.env.REACT_APP_REDIRECTURL!,
}

function  App() {
	return (
		<AuthenticationProvider {...authConfig} scopes={['openid', 'profile']}>
			<Auth/>
		</AuthenticationProvider>
	)
}
export default App;	